import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import { getApiRequest, apiUrl, postApiRequest } from '../../../../../utils/request';
import { isBrowser, formatPhoneFromServer, formatDateTime, formatDay } from '../../../../../utils/helpers';
import Loadable from '../../../../data/loadable';
import AdminTable from '../common/admin-table';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';

function buildDownloadUrl(token) {
  const query = queryString.parse(isBrowser ? window.location.search : '');
  query.token = token;
  let concatenated = `${apiUrl}/admin/registrations/csv?${queryString.stringify(query)}`;
  return concatenated.replace('/api/', '/');
}

function formatRow(item) {
  const {id, user, product, league, created_at: created, requested_team: teamRequest, center } = item;
  const {birthday_day: bdayDay, birthday_month: bdayMonth, birthday_year: bdayYear, email, name, phone: phoneRaw } = user;
  const { name: productName, start_date: startDate } = product;
  const { name: leagueName } = league;
  const { name: centerName } = center;
  const bday = bdayDay ? `${bdayMonth}/${bdayDay}/${bdayYear}` : 'NA';
  const phone = phoneRaw ? formatPhoneFromServer(phoneRaw) : '';
  return {
    product: <span>{productName}<br />Starts: {formatDay(startDate, 'M/D/Y')}<br />League: {leagueName}</span>,
    participant: <span><span className="font-bold">{name}</span><br />{email}{!!phone && <span><br />{phone}</span>}</span>,
    bday,
    centerName,
    teamRequest,
    created: formatDateTime(created),
    id,
  };
}

const AdminRegistrations = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/registrations',
        label: 'Registrations',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadRegistrations = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/registrations${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setRegistrations(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadRegistrations();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'participant',
      label: 'Participant',
    },
    {
      key: 'product',
      label: 'Product',
      // isBold: true,
    },
    {
      key: 'centerName',
      label: 'Center',
    },
    {
      key: 'bday',
      label: 'Birthday',
    },
    {
      key: 'teamRequest',
      label: 'Team',
    },
    {
      key: 'created',
      label: 'Date',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <Link
          to={`/app/admin/registration/view?id=${row.id}`}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          View
        </Link>
      ),
    },
  ];


  const baseUrl = '/app/admin/registrations';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/admin/registrations',
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
      },
      {
        name: 'center_id',
        label: 'Center',
        type: 'select-lookup',
        lookupUrl: '/options/centers/search',
        placeholder: 'Search centers by name',
      },
      {
        name: 'league_id',
        label: 'League',
        type: 'select',
        optionsUrl: '/options/leagues',
      },
      {
        name: 'product_id',
        label: 'Product',
        type: 'select-lookup',
        lookupUrl: '/options/products/search',
        placeholder: 'Search products by name',
      },
    ],
    submitLabel: 'Search Registrations',
  };

  const downloadCsv = async () => {
    try {
      const result = await postApiRequest('/auth/one-time-token', {});
      const { code: authcode } = result;
      window.open(buildDownloadUrl(authcode), '_blank');
    } catch (err) {
      console.log(err, 'error trying to download...');
    }
  }

  const actionLinks = [
    {
      label: 'Download',
      onClick: downloadCsv,
      secondary: true,
    },
  ];

  return (
    <AdminContainer>
      <SEO title="Registrations" />
      <Heading title="Registrations" links={actionLinks} />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Registrations" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default AdminRegistrations;
